import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import { AccountRMData } from '@core/models/admin/account';
import { GetAllRMsDatum } from '@core/models/admin/roles-and-permissions/get-all-rm.model';
import { AccountManagementService } from '@core/services/account-management.service';
import { LoadingService } from '@core/services/loading.service';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { SlideInRightModalComponent } from '@shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable, Subscription, forkJoin, map } from 'rxjs';

interface GetAllRMsDatumExtended extends GetAllRMsDatum {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
  is_assigned_to_account: boolean;
  is_current_rm: boolean;
}

@Component({
  selector: 'app-assign-rm-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputLabelComponent,
    CommonModule,
    SlideInRightModalComponent,
    NgxSkeletonLoaderModule,
    ConfirmDialogComponent,
  ],
  templateUrl: './assign-rm-form.component.html',
  styleUrl: './assign-rm-form.component.scss',
})
export class AssignRmFormComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  accountId!: string;
  searchQuery: string = '';
  originalRMs: GetAllRMsDatumExtended[] = [];
  filteredResultsCount!: number;

  private allRMsSubject$ = new BehaviorSubject<GetAllRMsDatumExtended[] | null>(
    []
  );
  private accountRmSubject$ = new BehaviorSubject<AccountRMData[] | null>(null);
  private isDialogOpenSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isDialogLoadingSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private rmInfoSubject$ = new BehaviorSubject<GetAllRMsDatumExtended | null>(
    null
  );
  // TODO: add the account name to the form
  private accountNameSubject$ = new BehaviorSubject<string | null>(null);
  private accountTypeSubject$ = new BehaviorSubject<string | null>(null);

  allRMs$ = this.allRMsSubject$.asObservable();
  isFetchingAllRMs$!: Observable<boolean>;
  isFetchingAccountRMs$!: Observable<boolean>;
  isDialogOpen$ = this.isDialogOpenSubject$.asObservable();
  isDialogLoading$ = this.isDialogLoadingSubject$.asObservable();
  rmInfo$ = this.rmInfoSubject$.asObservable();
  accountName$ = this.accountNameSubject$.asObservable();
  accountType$ = this.accountTypeSubject$.asObservable();

  private toast = inject(NotificationFacade);
  private accountService = inject(AccountManagementService);
  private loadingService = inject(LoadingService);
  private route = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);

  constructor() {
    this.isFetchingAllRMs$ = this.loadingService.getLoadingObservable(
      'get-all-relationship-managers'
    );
    this.isFetchingAccountRMs$ = this.loadingService.getLoadingObservable(
      'get-account-relation-manager'
    );
  }

  ngOnInit(): void {
    this.accountId =
      (this.route.parent?.parent?.snapshot.paramMap.get(
        'accountId'
      ) as string) || this.route.snapshot.params['accountId'];

    const accountTypes = ['individual', 'corporate', 'joint'];
    const accountType = accountTypes.find((type) =>
      this.router.url.includes(type)
    );

    if (accountType) {
      this.accountTypeSubject$.next(accountType);
    }

    if (this.accountId) {
      this.getAllRmData();
    }
  }

  getAllRmData() {
    const sub = forkJoin({
      allRMs: this.accountService.getAllRMs(),
      accountRMs: this.accountService.getAccountRelationManager(this.accountId),
    })
      .pipe(
        map(({ allRMs, accountRMs }) => {
          // Sort all RMs by first_name
          const sortedAllRMs = allRMs.data.sort((a, b) =>
            a.first_name.localeCompare(b.first_name)
          );

          // Mark RMs as unassigned by default
          const enrichedRMs = sortedAllRMs.map((rm) => ({
            ...rm,
            is_assigned_to_account: false,
            is_current_rm: false,
          }));

          if (accountRMs.data.length > 0) {
            // Update is_assigned_to_account for RMs assigned to the account
            accountRMs.data.forEach((accountRm) => {
              const matchedRM = enrichedRMs.find(
                (rm) => rm.id === accountRm.rm_id
              );
              if (matchedRM) {
                matchedRM.is_assigned_to_account = true;
                matchedRM.is_current_rm = accountRm.status === 'active';
              }
            });
          }

          return { enrichedRMs, accountRMs: accountRMs.data };
        })
      )
      .subscribe(({ enrichedRMs, accountRMs }) => {
        this.allRMsSubject$.next(enrichedRMs);
        this.originalRMs = enrichedRMs;

        if (accountRMs.length > 0) {
          this.accountRmSubject$.next(accountRMs);
        }
        this.cdr.detectChanges();
      });

    this.subs.push(sub);
  }

  assignRM(rm: GetAllRMsDatumExtended) {
    this.isDialogLoadingSubject$.next(true);
    this.accountService
      .assignRelationshipManager({
        admin_id: rm.id,
        account_id: this.accountId,
      })
      .subscribe({
        next: () => {
          this.toggleRmRadio(rm.id);
          this.isDialogLoadingSubject$.next(false);
          this.isDialogOpenSubject$.next(false);
          this.toast.success(
            `Successfully assigned ${rm.first_name} ${rm.last_name} as the Relationship Manager`
          );

          const accountTypes = ['individual', 'corporate', 'joint'];
          const accountType = accountTypes.find((type) =>
            this.router.url.includes(type)
          );

          if (accountType) {
            this.router.navigateByUrl(
              `/admin/accounts/${accountType}/${this.accountId}/rm-list`
            );
          } else {
            window.history.back();
          }
        },
        error: () => {
          this.isDialogLoadingSubject$.next(false);
        },
      });
  }

  onRmClick(event: Event, rm: GetAllRMsDatumExtended) {
    event.preventDefault(); // Prevent the radio button from being checked immediately

    this.rmInfoSubject$.next(rm);
    this.isDialogOpenSubject$.next(true);
  }

  toggleRmRadio(selectedRmId: string) {
    const updatedRMs = this.allRMsSubject$.value?.map((rm) => ({
      ...rm,
      is_assigned_to_account: rm.id === selectedRmId, // Only the selected RM is marked as true
    }));

    if (updatedRMs) {
      this.allRMsSubject$.next(updatedRMs);
    }
  }

  onSearchChange(event: Event) {
    const query = (event.target as HTMLInputElement).value;

    if (query.trim() === '') {
      // If the query is empty, reset to the original list
      this.allRMsSubject$.next(this.originalRMs);
      this.filteredResultsCount = this.originalRMs.length;
    } else {
      // Otherwise, filter the list based on the query
      const filteredRMs = this.originalRMs.filter((rm) =>
        `${rm.first_name} ${rm.last_name}`
          .toLowerCase()
          .includes(query.toLowerCase())
      );

      this.allRMsSubject$.next(filteredRMs);
      this.filteredResultsCount = filteredRMs.length;
    }
  }

  closeDialog() {
    this.isDialogOpenSubject$.next(false);
    this.rmInfoSubject$.next(null);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
